import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import _ from "lodash";
import moment from "moment";
import { toast, Flip } from 'react-toastify';


import applicationContants from "./applicationConstants";

import { apiCallBegan } from "./api"


import {
    notificationsByClientAndClientAccountFetchTimestampCleared
} from "./clients"


import {
    proceedToNextStep,
    uiQuoteCreatedToCleared,
    uiNewQuoteCreatedComplete,
    uiNewQuoteCreatedtOnError,
    uiNewQuoteCreatedInProgress,
    uiInvalidQuoteReceived,
    uiServerErrorReceived,
    
} from "./ui-request-quote"

import {
    campaignDetailsReceived
} from "./campaigns"

const {
    ENDPOINT_FOR_PENDING_QUOTE_SLICE,
    REFETCH_THRESHOLD_IN_MINUTES,
    getApplicationDeadlineTypeList,
    getStartDateTypeList,
    getStartDateTypeDbList,
    getEndDateTypeList,
    getEndDateTypeDbList,
} = applicationContants

const applicationDeadlineTypeList = getApplicationDeadlineTypeList();
const startDateTypeList = getStartDateTypeList();
const startDateTypeDbList = getStartDateTypeDbList();
const endDateTypeList = getEndDateTypeList();
const endDateTypeDbList = getEndDateTypeDbList();



/*
 //recommendations: This is independent of any quote and we query the recommendation engine to get a list of publications
*/
const emptyQuoteObject = {
    byIds:{},
    allIds:[],
    totalCount:0,
    idList:[],          //This will be having the list of ids for pagination
    activeQuoteId:null, //This is which quote we are currently working on
    campaignIdOnSuccess:null, //This will set only when the submission is successful as it will used to redirect user to the campaign detail
    loading:false,
    lastFetch:null,
    recommendations:[], //This will be a list of recommendations to be displayed in the select publication page
}

const toastNotificationMessages = {
    quoteDeletedSuccess:{
        message:"Draft quote has has been deleted.",
        position:{containerId: 'B', transition: Flip},
    },
    quoteDeletedFailed:{
        message:"We encountered an error. Please try again or contact JobElephant.",
        position:{containerId: 'B', transition: Flip},
    },
}

//Slice: Create reducers and actions
const slice = createSlice({
    name:"pendingQuotes",
    initialState:{},
    reducers:{
        activeQuoteIdSet:(pendingQuotes,action)=>{
            const {activeQuoteId,clientId,clientAccountId} = action.payload; 
            pendingQuotes[clientId][clientAccountId].activeQuoteId = activeQuoteId;
        },

        /*This is called when you want to load a given quote detail */
        pendingQuoteActive:(pendingQuotes,action)=>{
            const {clientId,clientAccountId,pendingQuote:quoteData} = action.payload; 
            const {id,recommendationsFromHorton:recommendationsFromHortonDb} = quoteData;
            pendingQuotes[clientId][clientAccountId].activeQuoteId = id;

            pendingQuotes[clientId][clientAccountId].byIds[id] = quoteData;
            if(!pendingQuotes[clientId][clientAccountId].allIds.includes(id)) 
            pendingQuotes[clientId][clientAccountId].allIds.push(id);

            if(recommendationsFromHortonDb !== ""){
                pendingQuotes[clientId][clientAccountId].recommendations = recommendationsFromHortonDb;
            }
            
            pendingQuotes[clientId][clientAccountId].loading=false;
        },

        pendingQuoteRemoveRequested:(pendingQuotes,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            pendingQuotes[clientId][clientAccountId].loading=true;
        },

        pendingQuoteRemoved:(pendingQuotes,action)=>{
            const {quoteId:pendingQuoteId,clientId,clientAccountId} = action.payload; 

            const pendingQuoteState = pendingQuotes[clientId][clientAccountId];

            delete pendingQuoteState.byIds[pendingQuoteId];

            let index = pendingQuoteState.allIds.indexOf(pendingQuoteId);
            if(index !== -1){
                pendingQuoteState.allIds.splice(index,1);
                pendingQuoteState.totalCount = pendingQuoteState.totalCount - 1;
                index = pendingQuoteState.idList.indexOf(pendingQuoteId);
                if(index !== -1)
                    pendingQuoteState.idList.splice(index,1);

                const {message,position} = toastNotificationMessages.quoteDeletedSuccess
                toast.success(message,position);    
            }
        },

        pendingQuoteRemovedFailed:(pendingQuotes,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            pendingQuotes[clientId][clientAccountId].loading=false;

            const {message,position} = toastNotificationMessages.quoteDeletedFailed;
            toast.error(message,position);   
        },

        /*This is when the quote is created or saved */
        pendingQuoteSaved:(pendingQuotes,action)=>{
            //console.log(action);
            
            const {clientId,clientAccountId,pendingQuote:quoteData} = action.payload; 
            const {id} = quoteData;
            pendingQuotes[clientId][clientAccountId].activeQuoteId = id;

            pendingQuotes[clientId][clientAccountId].byIds[id] = quoteData;
            if(!pendingQuotes[clientId][clientAccountId].allIds.includes(id)){ 
              pendingQuotes[clientId][clientAccountId].allIds.push(id);
              pendingQuotes[clientId][clientAccountId].lastFetch = "";
            }

            pendingQuotes[clientId][clientAccountId].loading=false;
        },

        /*This is when the pending quote item (publication) is created or saved */
        pendingQuotePublicationRemoved:(pendingQuotes,action)=>{
            const {clientId,clientAccountId,activeQuoteId,id:pendingQuoteItemId} = action.payload; 
            const pendingQuoteItemsManual = pendingQuotes[clientId][clientAccountId].byIds[activeQuoteId].pendingQuoteItems.manual;
            
            let index = null;
            pendingQuoteItemsManual.forEach((p,i)=>{
                if(p.id === pendingQuoteItemId)
                   index = i;
            })

            if(index >= 0)
             pendingQuoteItemsManual.splice(index,1);
            
            
        },

        /*This is when the pending quote item (publication) is created or saved */
        pendingQuotePublicationSaved:(pendingQuotes,action)=>{
            const {clientId,clientAccountId,pendingQuote:quoteData} = action.payload; 
            const {id} = quoteData;
            pendingQuotes[clientId][clientAccountId].activeQuoteId = id;

            pendingQuotes[clientId][clientAccountId].byIds[id] = quoteData;
            if(!pendingQuotes[clientId][clientAccountId].allIds.includes(id)){ 
              pendingQuotes[clientId][clientAccountId].allIds.push(id);
              pendingQuotes[clientId][clientAccountId].lastFetch = "";
            }

            pendingQuotes[clientId][clientAccountId].loading=false;
            
        },

      

        /*This will be called for the paginated list of quote records */
        pendingQuotesReceived:(pendingQuotes,action)=>{
            const {clientId,clientAccountId} = action.payload; 
            const {data,totalCount} = action.payload;
            pendingQuotes[clientId][clientAccountId].totalCount = totalCount;
            if(!_.isEmpty(data)){
                const idList = _.map(data,'id');
                pendingQuotes[clientId][clientAccountId].idList = idList;
                    
                data.forEach(pendingQuoteRecord=>{
                    const {id} = pendingQuoteRecord;
                    if(!pendingQuotes[clientId][clientAccountId].allIds.includes(id)) 
                        pendingQuotes[clientId][clientAccountId].allIds.push(id);
                    
                    pendingQuotes[clientId][clientAccountId].byIds[id] = pendingQuoteRecord;
                })
            }

            
            pendingQuotes[clientId][clientAccountId].loading=false;
            pendingQuotes[clientId][clientAccountId].lastFetch = Date.now();
        },

        pendingQuoteRequested:(pendingQuotes,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            pendingQuotes[clientId][clientAccountId].loading=true;
        },
        
        pendingQuoteRequestedFailed:(pendingQuotes,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            pendingQuotes[clientId][clientAccountId].loading = false;
        },

        pendingQuoteSubmittedFinal:(pendingQuotes,action)=>{
            const {campaign,clientId,clientAccountId} = action.payload;
            const {id:quoteId} = campaign;
            pendingQuotes[clientId][clientAccountId].campaignIdOnSuccess = quoteId;
            pendingQuotes[clientId][clientAccountId].activeQuoteId = null;
        },

        recommendationsReceived:(pendingQuotes,action)=>{
            const {clientId,clientAccountId,data} = action.payload; 
            if(!_.isEmpty(data) && pendingQuotes[clientId][clientAccountId].recommendations.length === 0){
                pendingQuotes[clientId][clientAccountId].recommendations = data;
            }

            pendingQuotes[clientId][clientAccountId].loading = false;

        },
        
        

        clientAndClientAccountAdded:(pendingQuotes,action)=>{
            const {clientId,clientAccountId} = action.payload;
            if(!_.has(pendingQuotes, `${clientId}.${clientAccountId}`)){
                pendingQuotes[clientId] = {};
                pendingQuotes[clientId][clientAccountId] = {...emptyQuoteObject}
            }
        }
        
    }
})

//---------------Actions & Reducer export setup----------------------------
const {
    activeQuoteIdSet,
    pendingQuoteActive,
    pendingQuotesReceived,
    pendingQuoteRemoveRequested,
    pendingQuoteRemoved,
    pendingQuoteRemovedFailed,
    pendingQuoteRequested,
    pendingQuoteRequestedFailed,
    pendingQuoteSaved,
    pendingQuoteSubmittedFinal,
    pendingQuotePublicationRemoved,
    pendingQuotePublicationSaved,
    recommendationsReceived,
    clientAndClientAccountAdded,
}  = slice.actions;

//Export reducer
export default slice.reducer;
//---------------End of Actions & Reducer export setup----------------------------


//-------------Export Action Creators-----------------------
const url = ENDPOINT_FOR_PENDING_QUOTE_SLICE;
const ROUTE_GET_PENDING_QUOTES = "getPendingQuotes";
const ROUTE_GET_RECOMMENDATIONS = "getRecommendations";
const ROUTE_GET_QUOTE_DETAILS = "getQuoteDetails";
const ROUTE_SAVE_MANUAL_PUBLICATION = "saveManualPublication";
const ROUTE_REMOVE_MANUAL_PUBLICATION = "removeManualPublication";
const ROUTE_SUBMIT_FINAL_QUOTE = "submitFinalQuote";


export const clearActiveQuoteId = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    dispatch(activeQuoteIdSet({
        activeQuoteId:null,
        clientId,
        clientAccountId,
    }))

} 

export const deleteQuote = (quoteId)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const  {pendingQuotes} = getState().entities;

    if(_.has(pendingQuotes,`${clientId}.${clientAccountId}.byIds.${quoteId}`)){ 
        const data = {
            activeView,
            clientId,
            clientAccountId,
            quoteId,
        }
    
        const url = `${ENDPOINT_FOR_PENDING_QUOTE_SLICE}/${quoteId}`;
        dispatch(apiCallBegan({
            url,
            method:"delete",
            data,
            onStart:[pendingQuoteRemoveRequested.type],
            onSuccess:[pendingQuoteRemoved.type],
            onError:[pendingQuoteRemovedFailed.type],
        }));
    }
}

/*
  We set the quote as an active quote
*/
export const loadPendingQuote = (quoteId)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const  {pendingQuotes} = getState().entities;

    if(_.has(pendingQuotes,`${clientId}.${clientAccountId}.byIds.${quoteId}`)){ 
        dispatch(activeQuoteIdSet({
            activeQuoteId:quoteId,
            clientId,
            clientAccountId,
        }))
        return;
    }

    const data = {
        activeView,
        clientId,
        clientAccountId,
        quoteId,
        activeView,
    }

    const url = `${ENDPOINT_FOR_PENDING_QUOTE_SLICE}/${ROUTE_GET_QUOTE_DETAILS}`;
    dispatch(apiCallBegan({
        url,
        method:"post",
        data,
        onStart:[pendingQuoteRequested.type],
        onSuccess:[pendingQuoteActive.type],
        onError:[pendingQuoteRequestedFailed.type,uiInvalidQuoteReceived.type],
    }));
} 

//This is to make a call to the database for the initial load to get data for all the views
export const loadPendingQuotesList = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const  {pendingQuotes} = getState().entities;

    if(_.has(pendingQuotes,`${clientId}.${clientAccountId}`)){
        //const {lastFetch} = pendingQuotes[clientId][clientAccountId];
        //const diffInMinutes = moment().diff(moment(lastFetch),'minutes');
        //if(diffInMinutes < REFETCH_THRESHOLD_IN_MINUTES) return;
    }
    
    const query = {...getState().ui.myCampaignPendingQuoteView.query};
    query.activeView = activeView;
    query.clientId = clientId;
    query.clientAccountId = clientAccountId;
    
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_PENDING_QUOTES}`,
        method:'post',
        data:query,
        onStart:[pendingQuoteRequested.type],
        onSuccess:[pendingQuotesReceived.type],
        onError:[pendingQuoteRequestedFailed.type],
    }))
    
}

/*
    job_title [string]
    job_description [string]
    user_id [string]
    company_id [number]            // Job Target
    client_id [number] (optional)  // Harvard
    omit_publications [array[number]] (optional)
    zipcode [string] (optional)
*/
export const loadRecommendations = (selectedQuotedId=null)=>(dispatch,getState)=>{
    const {activeView,accessControlList}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const  {pendingQuotes} = getState().entities;

    if(_.has(pendingQuotes,`${clientId}.${clientAccountId}`)){
        const {recommendations} = pendingQuotes[clientId][clientAccountId];

        if(recommendations.length) return;
    }
    let quoteId = null;
    quoteId = (selectedQuotedId) ?selectedQuotedId:pendingQuotes[clientId][clientAccountId].activeQuoteId
    const {clientAccountById} = accessControlList;
    if(quoteId && clientAccountById && clientAccountById[clientAccountId]){
    
        const {onBehalfOfClientId} = clientAccountById[clientAccountId];
        const {userId} = activeView;
        const quoteDetails = pendingQuotes[clientId][clientAccountId].byIds[quoteId];

        const {
            campaignTitle,
            jobDescription,
        } = quoteDetails;
        
        const query = {
            activeView,
            clientId ,
            clientAccountId,
            onBehalfOfClientId,
            userId,
            activeQuoteId:quoteId,
            campaignTitle,
            jobDescription,

        };
        dispatch(apiCallBegan({
            url:`${url}/${ROUTE_GET_RECOMMENDATIONS}`,
            method:'post',
            data:query,
            onStart:[pendingQuoteRequested.type],
            onSuccess:[recommendationsReceived.type],
            onError:[pendingQuoteRequestedFailed.type],
        }))
    }
    
}

/*
  We have not saved the quote in the regular form so we need to get the 
  campaignTitle,
  jobDescription from the state directly
*/
export const loadRecommendationsForRegularForm = (formData)=>(dispatch,getState)=>{
    const {activeView,accessControlList}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const  {pendingQuotes} = getState().entities;

    if(_.has(pendingQuotes,`${clientId}.${clientAccountId}`)){
        const {recommendations} = pendingQuotes[clientId][clientAccountId];

        if(recommendations.length) return;
    }

    const quoteId = null;
    
    const {clientAccountById} = accessControlList;
    const {onBehalfOfClientId} = clientAccountById[clientAccountId];
    const {userId} = activeView;
    
    const {
        campaignTitle,
        jobDescription,
    } = formData;
    
    const query = {
        activeView,
        clientId ,
        clientAccountId,
        onBehalfOfClientId,
        userId,
        activeQuoteId:quoteId,
        campaignTitle,
        jobDescription,

    };
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_RECOMMENDATIONS}`,
        method:'post',
        data:query,
        onStart:[pendingQuoteRequested.type],
        onSuccess:[recommendationsReceived.type],
        onError:[pendingQuoteRequestedFailed.type],
    }))
    
    
}

export const removeManualPublication = formData=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const {pendingQuotes} = getState().entities;
    
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;

    const quoteId = pendingQuotes[clientId][clientAccountId].activeQuoteId;
    
    const data = {
        activeView,
        clientId,
        clientAccountId,
        activeQuoteId:quoteId,
        ...formData,
    }
    
    const method = "post";
    const url = `${ENDPOINT_FOR_PENDING_QUOTE_SLICE}/${ROUTE_REMOVE_MANUAL_PUBLICATION}`;

    let onStart = [pendingQuoteRequested.type]
    let onSuccess = [pendingQuotePublicationRemoved.type,proceedToNextStep.type]
    let onError = [pendingQuoteRequestedFailed.type,uiServerErrorReceived.type]
    
    //console.log(data);
    
    dispatch(apiCallBegan({
        url,
        method,
        data,
        onStart,
        onSuccess,
        onError,
    }));
    
}

//Save Publication items
export const saveManualPublication = formData=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const {pendingQuotes} = getState().entities;
    
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;

    const quoteId = pendingQuotes[clientId][clientAccountId].activeQuoteId;
    
    const data = {
        activeView,
        clientId,
        clientAccountId,
        activeQuoteId:quoteId,
        ...formData,
    }
    
    const method = "post";
    const url = `${ENDPOINT_FOR_PENDING_QUOTE_SLICE}/${ROUTE_SAVE_MANUAL_PUBLICATION}`;

    let onStart = [pendingQuoteRequested.type]
    let onSuccess = [pendingQuotePublicationSaved.type,proceedToNextStep.type]
    let onError = [pendingQuoteRequestedFailed.type,uiServerErrorReceived.type]
    
    //console.log(data);
    
    dispatch(apiCallBegan({
        url,
        method,
        data,
        onStart,
        onSuccess,
        onError,
    }));
    
}

/*
  Save a pending quote to the database
  Add a quote - This will be triggered by request a quote
  It needs to set the active quote id that is being processed
*/
export const saveQuote = formData=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const {pendingQuotes} = getState().entities;
    
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;

    const activeQuoteId = pendingQuotes[clientId][clientAccountId].activeQuoteId;
    
    const createQuoteFlag  = (activeQuoteId !== null) ? false:true;

    const method = (!createQuoteFlag)? "put":"post";
    const url = (!createQuoteFlag) ? 
        `${ENDPOINT_FOR_PENDING_QUOTE_SLICE}/${activeQuoteId}`: ENDPOINT_FOR_PENDING_QUOTE_SLICE;

    const data = {
        activeView,
        clientId,
        clientAccountId,
        ...formData,
    }

    let onStart = [pendingQuoteRequested.type]
    let onSuccess = [pendingQuoteSaved.type]
    let onError = [pendingQuoteRequestedFailed.type,uiServerErrorReceived.type]
    
    if(createQuoteFlag){
        onStart = [uiNewQuoteCreatedInProgress.type,pendingQuoteRequested.type]
        onSuccess = [pendingQuoteSaved.type,uiNewQuoteCreatedComplete.type]
        onError = [pendingQuoteRequestedFailed.type,uiNewQuoteCreatedtOnError.type]
    }
    else{
        //This flag is when we want to save but not proceed to the next step, currently used for add manual publication
        if(formData.saveWithoutProceedingToNextStepFlag && formData.saveWithoutProceedingToNextStepFlag === true)
            onSuccess = [pendingQuoteSaved.type]
        else
            onSuccess = [pendingQuoteSaved.type,proceedToNextStep.type]
    }

    dispatch(apiCallBegan({
        url,
        method,
        data,
        onStart,
        onSuccess,
        onError,
    }));
}

/*
  This gets called from the friendly form (Final step) and there is a pending quote record 
  
  Application deadline
    Open until filled 
    - We will be populating quotes.stop_date field 
    - We will blank out quotes.stop_date if open until filled is selected

    End date of ads:
    If Open until filled is selected we hide the “Stop on application deadline”
    Maximize Run time 
    - Leave the “quotes.end_date_for_all_ads” blank

*/
export const submitFinalQuote = formData=>(dispatch,getState)=>{
    const {activeView,accessControlList}  = getState().auth;
    const {pendingQuotes} = getState().entities;
    
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    const userUUID = activeView.user.id_uuid;
    const userId = activeView.user.id;

    const {clientAccountById} = accessControlList;
    const {onBehalfOfClient} = clientAccountById[clientAccountId];
    
    //If contracts were selected then bind the contract constructs in the payload
    let listOfSelectedContracts = [];
    if(formData.contracts.length){
        const {contracts} = onBehalfOfClient;
        listOfSelectedContracts = formData.contracts.map(c=>{
            return _.find(contracts,{'id':c})
        })
    }
    
    const activeQuoteId = pendingQuotes[clientId][clientAccountId].activeQuoteId;
    
    const method = "post";
    const url = `${ENDPOINT_FOR_PENDING_QUOTE_SLICE}/${ROUTE_SUBMIT_FINAL_QUOTE}`;

    const formDataForSubmission = {...formData};
    const {deadlineDate,deadlineType,startDate,startDateType,endDate,endDateType} = formDataForSubmission;
    
    formDataForSubmission.deadlineDate = (deadlineDate !== null) ? new Date(deadlineDate).toJSON():""
    formDataForSubmission.openUntilFilled = (deadlineType === applicationDeadlineTypeList.OPEN_UNTIL_FILLED) ? true:false;
    formDataForSubmission.startDate = (startDate !== null) ? new Date(startDate).toJSON():""
    formDataForSubmission.endDate = (endDate !== null) ? new Date(endDate).toJSON():""

    if(startDateType === startDateTypeList.IMMEDIATELY) formDataForSubmission.startDateTypeDb = startDateTypeDbList.IMMEDIATELY;
    if(startDateType === startDateTypeList.FUTURE_DATE) formDataForSubmission.startDateTypeDb = startDateTypeDbList.FUTURE_DATE;
    
    if(endDateType === endDateTypeList.MAXIMIZE_RUNTIME) formDataForSubmission.endDateTypeDb = endDateTypeDbList.MAXIMIZE_RUNTIME;
    if(endDateType === endDateTypeList.STOP_ON_FUTURE_DATE) formDataForSubmission.endDateTypeDb = endDateTypeDbList.STOP_ON_FUTURE_DATE;
    if(endDateType === endDateTypeList.STOP_ON_APPLICATION_DEADLINE) formDataForSubmission.endDateTypeDb = endDateTypeDbList.STOP_ON_APPLICATION_DEADLINE;
    
    const data = {
        activeView,
        clientId,
        clientAccountId,
        userUUID,
        userId,
        ...formDataForSubmission,
        listOfSelectedContracts,
    }

    let onStart = [pendingQuoteRequested.type]
    let onSuccess = [
        pendingQuoteSubmittedFinal.type,
        campaignDetailsReceived.type,
        proceedToNextStep.type,
        notificationsByClientAndClientAccountFetchTimestampCleared.type
    ]
    let onError = [pendingQuoteRequestedFailed.type,uiServerErrorReceived.type]

    if(activeQuoteId){
        onSuccess.push(pendingQuoteRemoved.type);
    }
    
    dispatch(apiCallBegan({
        url,
        method,
        data,
        onStart,
        onSuccess,
        onError,
    }));

}




export const setClientAndClientAccount = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    dispatch(clientAndClientAccountAdded({
        clientId,
        clientAccountId,
    }))

}

//-------------End of Export Action Creators-----------------------

//------------------Selectors-----------------------

export const getActiveQuoteId = createSelector(
    state=>state.entities.pendingQuotes,
    state=>state.auth,
    (pendingQuotes,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  
        return (_.has(pendingQuotes,`${clientId}.${clientAccountId}.activeQuoteId`))? 
           pendingQuotes[clientId][clientAccountId].activeQuoteId:null
    }
)

export const getCampaignIdOnSuccessfulSubmission = createSelector(
    state=>state.entities.pendingQuotes,
    state=>state.auth,
    (pendingQuotes,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  
        return (_.has(pendingQuotes,`${clientId}.${clientAccountId}.campaignIdOnSuccess`))? 
           pendingQuotes[clientId][clientAccountId].campaignIdOnSuccess:null
    }
)


export const getPendingQuoteList = createSelector(
    state=>state.entities.pendingQuotes,
    state=>state.auth,
    (pendingQuotes,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  
        return (_.has(pendingQuotes,`${clientId}.${clientAccountId}`))? pendingQuotes[clientId][clientAccountId]:{...emptyQuoteObject}
    }
)

export const getRecommendations = createSelector(
    state=>state.entities.pendingQuotes,
    state=>state.auth,
    (pendingQuotes,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  
        return (_.has(pendingQuotes,`${clientId}.${clientAccountId}`))? pendingQuotes[clientId][clientAccountId].recommendations:[]
    }
)

export const getQuote = createSelector(
    state=>state.entities.pendingQuotes,
    state=>state.auth,
    (pendingQuotes,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  
        const quoteId  = (_.has(pendingQuotes,`${clientId}.${clientAccountId}.activeQuoteId`))? 
           pendingQuotes[clientId][clientAccountId].activeQuoteId:null
        
        if(quoteId !== null){
            return (_.has(pendingQuotes,`${clientId}.${clientAccountId}.byIds.${quoteId}`))? 
            pendingQuotes[clientId][clientAccountId].byIds[quoteId]:null;
        }
        else
           return null;
      
    }
)

//----------------End of Selectors-----------------------

import React from 'react';
import moment from 'moment';

function FooterMinimalLayout() {
    return (
        <div
            className="d-flex justify-content-center py-3 text-black-50">
            <div className="app-footer--second">
                <span>JobElephant</span> ©
                {moment().format('YYYY')}
                {" | "}
                <a href="https://jobelephant.com/w/wp-content/uploads/2024/10/CPortal-WCAG-2.1-VPAT_ACR_Statement_10_24.pdf" target="_blank" title="Voluntary Product Accessibility Template">
                Voluntary Product Accessibility Template (VPAT)
                </a>
            </div>
        </div>
    );
}

export default FooterMinimalLayout;